

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class InsectPest extends Vue {
  private types = ['是', '否']
  typeList = []
  states = ['空闲', '使用中', '待维修', '维保中', '终止使用']
  private searchInfo = {
    machineryNameCode: '',
    machineryType: '',
    machineryState: '',
    dueTermState: ''
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  activated () {
    this.loadData()
  }

  created () {
    this.loadData()
    this.getTypeList()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  // 类型
  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      this.typeList = res.machineryType || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  goAdd () {
    this.$router.push({ name: 'machineAdd' })
  }

  onDetail (item: { machineryId: string }) {
    this.$router.push({
      name: 'machineDetail',
      query: { id: item.machineryId }
    })
  }
}
